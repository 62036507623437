<template>
    <!--vue-web-cam package with face-api.js (Tensorflow) -->
    <div>
        <div class="flex justify-center">
            <!--	Live feed
			-------------------------------------------------------------------------- -->
            <div class="z-50">
                <vue-web-cam
                    :device-id="deviceId"
                    @cameras="onCameras"
                    @error="onError"
                    @started="onStarted"
                    class="webcam"
                    ref="webcam"
                />
                <!--<canvas class="webcam-overlay" ref="overlay"></canvas>-->
            </div>

            <div class="mx-4 text-2xl items-center flex">
                <arrow-right class="rotate-90" v-if="!initializing"></arrow-right>
            </div>

            <div class="flex justify-center items-end items-center">
                <!--	Placeholder
				-------------------------------------------------------------------------- -->
                <div v-show="!faceFoundButNotYetExtracted && !extractedImage">
                    <div
                        class="photo-size border-solid border-4 border-red-400 flex items-center text-center font-bold"
                        style="background: url('img/css/user.png') no-repeat center / cover rgb(219, 224, 230)"
                        v-show="!initializing && canvases.length === 0"
                        v-trans="'no-face-found'"
                    >
                        Geen gezicht gevonden.
                    </div>
                </div>

                <!--	Livefeed - focused
				-------------------------------------------------------------------------- -->
                <canvas
                    class="photo-size border-solid border-4 border-green-400"
                    ref="extractedCanvas"
                    v-show="faceFoundButNotYetExtracted"
                ></canvas>

                <!--	Captured face
				-------------------------------------------------------------------------- -->
                <img
                    :src="extractedImage"
                    class="photo-size border-solid border-4 border-grey-600"
                    v-show="extractedImage"
                />
            </div>
        </div>

        <div class="alert alert--warning" v-show="error">{{ error }}</div>

        <div v-show="initializing && !error" v-trans="'please-wait'" class="abs-center-tooltip">Even geduld ...</div>

        <div class="mt-4 flex justify-center md:w-1/2 mx-auto">
            <button @click="$emit('cancel')" class="mr-2 btn" type="button" v-trans="'cancel'">Annuleren</button>
            <button
                @click="extractedImage = null"
                class="mr-2 btn"
                type="button"
                v-show="extractedImage"
                v-trans="'again'"
            >
                Opnieuw
            </button>
            <button
                @click="onCapture"
                class="mr-2 btn btn--primary"
                type="button"
                v-show="!initializing && !extractedImage"
                v-trans="'take-photo'"
            >
                Neem foto
            </button>
            <button
                @click="$emit('save', extractedImage)"
                class="mr-2 btn btn--primary"
                type="button"
                v-show="extractedImage"
                v-trans="'save'"
            >
                Opslaan
            </button>
        </div>
    </div>
</template>

<script>
import { WebCam } from "vue-web-cam";
import * as faceapi from "face-api.js/build/es6";
import ArrowRight from "vue-material-design-icons/Triangle";

export default {
    name: "CapturePhoto",
    components: {
        "vue-web-cam": WebCam,
        ArrowRight,
    },
    data() {
        return {
            WEIGHTS_URL: "/tensorflow-models",
            deviceId: null,
            extractedImage: null,
            canvases: [],
            error: "",
            initializing: true,
        };
    },

    async beforeMount() {
        await faceapi.loadTinyFaceDetectorModel(this.WEIGHTS_URL);
        // await faceapi.loadFaceLandmarkTinyModel(this.WEIGHTS_URL);
        // await faceapi.loadFaceLandmarkModel(this.WEIGHTS_URL);
        // await faceapi.loadFaceRecognitionModel(this.WEIGHTS_URL);
        // await faceapi.loadFaceExpressionModel(this.WEIGHTS_URL);
        // await faceapi.loadAgeGenderModel(this.WEIGHTS_URL);
        // await faceapi.loadFaceDetectionModel(this.WEIGHTS_URL);
    },

    computed: {
        faceFoundButNotYetExtracted() {
            return this.canvases.length > 0 && !this.extractedImage;
        },
    },

    methods: {
        async detectFace() {
            if (!this.$refs.webcam) {
                setTimeout(this.detectFace, 0);
                return;
            }
            //const input = this.$refs[`photo-${index}`][0];
            const options = new faceapi.TinyFaceDetectorOptions({
                // size at which image is processed, the smaller the faster,
                // but less precise in detecting smaller faces, must be divisible
                // by 32, common sizes are 128, 160, 224, 320, 416, 512, 608,
                // for face tracking via webcam I would recommend using smaller sizes,
                // e.g. 128, 160, for detecting smaller faces use larger sizes, e.g. 512, 608
                // default: 416
                inputSize: 160,
                scoreThreshold: 0.3,
            });
            const detection = await faceapi.detectSingleFace(this.$refs.webcam.$el, options);
            if (this.$refs.webcam && this.$refs.webcam.$el && detection) {
                const offsetTop = Math.round(detection.box.height / 2);
                const offsetHorizontal = Math.round(detection.box.width / 10);
                const width = detection.box.width + offsetHorizontal + offsetHorizontal;
                const height = Math.round((width * 4) / 3);
                const regionsToExtract = [
                    new faceapi.Rect(detection.box.x - offsetHorizontal, detection.box.y - offsetTop, width, height),
                ];

                this.canvases = await faceapi.extractFaces(this.$refs.webcam.$el, regionsToExtract);
                // console.log("c:", this.canvases);
                if (this.$refs.extractedCanvas) {
                    const extractContext = this.$refs.extractedCanvas.getContext("2d");
                    this.$refs.extractedCanvas.width = this.canvases[0].width;
                    this.$refs.extractedCanvas.height = this.canvases[0].height;
                    extractContext.drawImage(this.canvases[0], 0, 0);
                }
            } else {
                this.canvases = [];
            }

            //iterate this function
            setTimeout(this.detectFace, 10);
        },
        onCameras(cameras) {
            this.deviceId = cameras[0].deviceId;
        },
        onCapture() {
            console.log("onCapture");
            if (this.canvases.length > 0) {
                this.extractedImage = this.$refs.extractedCanvas.toDataURL("image/jpeg");
            } else {
                let webcamCanvas = this.$refs.webcam.getCanvas();
                console.log(webcamCanvas);
                const x = Math.round((webcamCanvas.width - 180) / 2);
                const y = Math.round((webcamCanvas.height - 225) / 2);
                webcamCanvas = this.cropCanvas(webcamCanvas, x, y, 180, 225);
                this.extractedImage = webcamCanvas.toDataURL("image/jpeg");
            }
        },
        onStarted() {
            this.initializing = false;
            //console.log("On Started Event", stream);
            setTimeout(this.detectFace, 1000);
            this.error = "";
        },
        onError(error) {
            console.log("capture err:", error);
            this.error = this.$trans.getTranslation(error.name, error.message);
        },

        cropCanvas(img, x, y, w, h) {
            const ccanvas = document.createElement("canvas");
            const cctx = ccanvas.getContext("2d");

            ccanvas.width = w;
            ccanvas.height = h;

            // draw with crop arguments
            cctx.drawImage(img, x, y, w, h, 0, 0, w, h);

            return ccanvas;
        },
    },
};
</script>

<style>
/*.final-image {*/
/*height: 290px !important;*/
/*width: 250px !important;*/
/*overflow: hidden;*/
/*}*/

/*.extracted-canvas {*/
/*height: 290px !important;*/
/*}*/
.webcam {
    width: 400px;
    height: 300px;
}

.webcam-169 {
    height: 225px;
    width: 400px;
}

/*.webcam-overlay {*/
/*width: 400px;*/
/*position: absolute;*/
/*top: 0;*/
/*left: 0;*/
/*}*/
</style>
